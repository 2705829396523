<template>
    <!-- 合作伙伴 -->
    <div class="partner2">
        <div class="p2Box">
            <div class="p2b1">
                <div class="p1Left">
                    <img src="http://iv.vu818.com/img/vu818.png" alt="" class="p1l" />
                </div>
                <div class="p1Right">
                    <input type="text" placeholder="品牌方\产品" class="p1rInp" />
                    <div class="p1rBtn">
                        <img src="http://iv.vu818.com/img/vu818.png" alt="" class="pbIcon" />
                    </div>
                </div>
            </div>
            <partner-list :type="2"></partner-list>
        </div>
    </div>
</template>

<script>
import PartnerList from "@/components/content/Partner/PartnerList.vue";
export default {
    components: { PartnerList },
    name: "Partner2",
};
</script>

<style lang="less" scoped>
.partner2 {
    width: 100%;
    margin-top: 78px;
    overflow: hidden;
    .p2Box {
        max-width: 1200px;
        margin: 0 auto;
        margin-top: 100px;
        overflow: hidden;
        .p2b1 {
            width: 100%;
            height: 53px;
            .p1Left {
                width: 240px;
                height: 46px;
                float: left;
                position: absolute;
                overflow: hidden;
                .p1l {
                    position: absolute;
                    bottom: -1120px;
                    left: 15px;
                }
            }

            .p1Right {
                width: 360px;
                height: 45px;
                float: right;
                background-color: white;
                position: relative;
                box-shadow: 1px 1px 10px 1px gainsboro;
                border-radius: 5px;
                margin-top: 3px;
                margin-right: 10px;
                .p1rInp {
                    width: 100%;
                    height: 100%;
                    padding: 0;
                    padding-left: 10px;
                    border: none;
                    outline: none;
                    border-radius: 5px;
                    box-sizing: border-box;
                }
                .p1rBtn {
                    width: 40px;
                    height: 45px;
                    background-color: white;
                    border-top-right-radius: 5px;
                    border-bottom-right-radius: 5px;
                    position: absolute;
                    right: 0;
                    top: 0;
                    overflow: hidden;
                    .pbIcon {
                        width: 760px;
                        height: 1300px;
                        position: absolute;
                        top: -340px;
                        left: -276px;
                    }
                }
            }
        }
    }
}
</style>
